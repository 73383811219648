const packageJson = require('../../../../package.json')
const gitVersion = require('../assets/git-version.json')

export const environment = {
  appName: 'Alpiq Flex Portal',
  envName: 'DEV',
  apiBaseUrl: 'https://flexportal-api.dev.dip2.alpiq.services',
  // apiBaseUrl: 'https://flexportal-api.int.dip2.alpiq.services',
  defaultLanguage: 'de',
  hmr: false,
  production: false,
  test: true,
  i18nPrefix: '',
  versions: {
    app: packageJson.version + '-' + gitVersion.hash,
    angular: packageJson.dependencies['@angular/core'],
    ngrx: packageJson.dependencies['@ngrx/store'],
    material: packageJson.dependencies['@angular/material'],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    ngxtranslate: packageJson.dependencies['@ngx-translate/core'],
    fontAwesome: packageJson.dependencies['@fortawesome/fontawesome-free-webfonts'],
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies.typescript,
    cypress: packageJson.devDependencies.cypress,
  },
}
