import browser from 'browser-detect'
import { AfterViewInit, Component, Inject, OnInit, OnDestroy } from '@angular/core'
import { Store, select, props } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { environment as env } from '../../environments/environment'

type NavigationItem = {
  fragment?: string
  isSecondary?: boolean // we got with flat structure for now
  label: string
  link: string
}

import {
  authLogin,
  authLogout,
  routeAnimations,
  AppState,
  LocalStorageService,
  selectIsAuthenticated,
  selectSettingsStickyHeader,
  selectSettingsLanguage,
  selectEffectiveTheme,
} from '../core/core.module'
import {
  actionSettingsChangeAnimationsPageDisabled,
  actionSettingsChangeLanguage,
} from '../core/settings/settings.actions'
import { DOCUMENT } from '@angular/common'
import { apiSuccess, getAssets, setAsset, windowScrolled } from '../core/api/actions'
import { filter, takeUntil, tap } from 'rxjs/operators'
import { selectAssets, selectCurrentAsset } from '../core/api/selectors'
import { MatSelectChange } from '@angular/material/select'

@Component({
  selector: 'anms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private _unsubscribeS$: Subject<any> = new Subject<any>()

  isProd = env.production
  envName = env.envName
  version = env.versions.app
  year = new Date().getFullYear()
  logo = require('../../assets/ALPIQ_logo.svg')
  languages = ['de', 'en']
  navigation: NavigationItem[] = [
    { link: 'overview', label: 'menu.overview' },
    {
      fragment: 'production',
      isSecondary: true,
      label: 'Production',
      link: 'overview/production',
    },
    {
      fragment: 'storage',
      link: 'overview/storage',
      label: 'Storage',
      isSecondary: true,
    },
    {
      fragment: 'unit',
      isSecondary: true,
      label: 'Unit',
      link: 'overview/unit',
    }, // TODO unit1 thru unit2

    { link: 'availability', label: 'availability' },
  ]
  navigationSideMenu = [...this.navigation, { link: 'settings', label: 'menu.settings' }]
  navigationTopMenu = this.navigation.filter(e => !e.isSecondary)

  // TODO finishme
  /*assets = [{ value: 'Asset1' }, { value: 'Asset2' }]
  asset = {
    value: 'Asset',
  }
  customers = [{ value: 'Customer1' }, { value: 'Customer2' }]
  customer = {
    value: 'Pick customer',
  }*/
  assets: any[] = []
  asset: any
  assets$: Observable<any[]>

  isAuthenticated$: Observable<boolean>
  stickyHeader$: Observable<boolean>
  language$: Observable<string>
  theme$: Observable<string>

  document: any

  constructor(
    private store: Store<AppState>,
    private storageService: LocalStorageService,
    @Inject(DOCUMENT) document
  ) {}

  private static cantRunAnimation() {
    return ['ie', 'edge', 'safari'].includes(browser().name)
  }

  ngOnInit(): void {
    this.storageService.testLocalStorage()
    if (AppComponent.cantRunAnimation()) {
      this.store.dispatch(
        actionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true,
        })
      )
    }

    this.isAuthenticated$ = this.store.pipe(select(selectIsAuthenticated))
    this.stickyHeader$ = this.store.pipe(select(selectSettingsStickyHeader))
    this.language$ = this.store.pipe(select(selectSettingsLanguage))
    this.theme$ = this.store.pipe(select(selectEffectiveTheme))

    this.store.dispatch(getAssets({}))
    this.assets$ = this.store.pipe(
      select(selectAssets),
      filter((values: any[]) => !!values && values.length > 0),
      tap((values: any[]) => {
        this.assets = values
        this.asset = values[0]
        //first asset selection
        this.assetChange(this.asset)
      })
    )
  }

  ngAfterViewInit() {
    let list = document.getElementsByTagName('mat-sidenav-content')

    for (let i = 0; i < list.length; i++) {
      let k = list[i]
      k.addEventListener('scroll', e => {
        this.store.dispatch(windowScrolled({ scrollTop: k.scrollTop }))
      })
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeS$.next()
    this._unsubscribeS$.complete()
  }

  onLogoutClick() {
    this.store.dispatch(authLogout())
  }

  onLanguageSelect({ value: language }) {
    this.store.dispatch(actionSettingsChangeLanguage({ language }))
  }

  assetChange(asset: any) {
    this.store.dispatch(setAsset({ asset: asset }))
  }
}
