import { NgModule } from '@angular/core'
import { Routes, RouterModule, PreloadAllModules } from '@angular/router'
import { LoginComponent } from './features/login/component'
import { AuthGuardService } from './core/core.module'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'about',
    loadChildren: () => import('./features/about/about.module').then(m => m.AboutModule),
  },
  {
    path: 'overview',
    loadChildren: () =>
      import('./features/overview/overview.module').then(m => m.OverviewModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'login',
    component: LoginComponent,
  },

  {
    path: 'availability',
    loadChildren: () =>
      import('./features/availability/module').then(m => m.AvailabilityModule),
    canActivate: [AuthGuardService],
  },
  // TODO remove those before October
  {
    path: 'feature-list',
    loadChildren: () =>
      import('./features/feature-list/feature-list.module').then(
        m => m.FeatureListModule
      ),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./features/settings/settings.module').then(m => m.SettingsModule),
  },
  {
    path: 'examples',
    loadChildren: () =>
      import('./features/examples/examples.module').then(m => m.ExamplesModule),
  },
  {
    path: '**',
    redirectTo: 'overview',
  },
]

@NgModule({
  // useHash supports github.io demo page, remove in your app
  imports: [
    RouterModule.forRoot(routes, {
      // anchorScrolling: 'enabled',
      preloadingStrategy: PreloadAllModules,
      // scrollPositionRestoration: 'enabled',
      // useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
