import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ScannedActionsSubject, Store } from '@ngrx/store'
import { AppState } from '../../core/core.state'
import {
  authLogin,
  authLoginFailedName,
  authLoginSuccessName,
} from '../../core/auth/auth.actions'
import { filter } from 'rxjs/operators'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-login',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup

  error: string

  constructor(
    private store: Store<AppState>,
    private actions$: ScannedActionsSubject,
    private router: Router,
    private t: TranslateService
  ) {}

  ngOnInit() {
    this.initForm()

    // Look for an error action on Login
    this.actions$
      .pipe(filter(action => action.type === authLoginFailedName))
      .subscribe(action => (this.error = 'Username/Password is incorrect'))

    // Look for a success action on Login
    this.actions$
      .pipe(filter(action => action.type === authLoginSuccessName))
      .subscribe(action => this.router.navigate(['/overview']))
  }

  initForm() {
    this.form = new FormGroup({
      username: new FormControl('', { validators: Validators.required }),
      password: new FormControl('', { validators: Validators.required }),
    })
  }

  login() {
    this.error = ''
    this.store.dispatch(
      authLogin({
        username: this.form.get('username').value,
        password: this.form.get('password').value,
      })
    )
  }
}
