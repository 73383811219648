import { createSelector, select } from '@ngrx/store'

// import { SettingsState } from './settings.model'
import { selectApiState } from '../core.state'
import { ApiState } from './reducer'
import { filter } from 'rxjs/operators'
import { Observable } from 'rxjs'

export const selectOverviewSummary = createSelector(
  selectApiState,
  (state: ApiState) => state.reporting['summary/asset']
)

export const selectChartFilter = createSelector(
  selectApiState,
  (state: ApiState) => state['charts/refilter']
)

export const selectOverviewGenerated = createSelector(
  selectApiState,
  (state: ApiState) => state['charts/overview_generate']
)
export const selectOverviewGeneratedDone = store => {
  return store.pipe(select(selectOverviewGenerated)).pipe(filter(a => !!a))
}

export const selectWindowScrolled = createSelector(
  selectApiState,
  (state: ApiState) => state['window/scrolled']
)

export const selectAssetAvailabilitySchedule = createSelector(
  selectApiState,
  (state: ApiState) => state.asset['availability/schedule']
)

export const selectAssets = createSelector(
  selectApiState,
  // @ts-ignore
  (state: ApiState) => state.asset.assets
)

export const selectCurrentAsset = createSelector(
  selectApiState,
  // @ts-ignore
  //(state: ApiState) => state.asset.assets[0]
  //removed hardcoded first asset selection and replaced with a dynamic one
  (state: ApiState) => state.asset.asset
)

export const selectCurrentAssetWhenLoaded = store => {
  return store.pipe(select(selectCurrentAsset)).pipe(filter(a => !!a))
}

export const selectCurrentAvailAbility = createSelector(
  selectApiState,
  // @ts-ignore TODO better to pick state.availability.availability
  (state: ApiState) => state.availability.availabilities[0]
)

// Just a helper method
export const selectSchedule = createSelector(
  selectApiState,
  (state: ApiState) => state['schedule']
)
