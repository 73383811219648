import { Injectable } from '@angular/core'
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects'
import { catchError, filter, tap, withLatestFrom } from 'rxjs/operators'
import { Action, select, Store } from '@ngrx/store'
import { switchMap } from 'rxjs/internal/operators'

import { ApiState } from './reducer'
import {
  AssetAvailabilityResponses,
  AssetAvailabilityScheduleResponse,
  AssetsAvailabilityScheduleService,
  AssetsAvailabilityService,
  AssetsAvailabilityTimeseriesService,
  AssetsService,
  AssetResponses,
  AssetsUnitsService,
  AssetsStoragesService,
  AssetsStorageResponses,
  AssetUnitResponses,
  AssetAvailability,
  AssetAvailabilities,
  AssetAvailabilityTimeseries,
  ReportingSummaryService,
} from 'common'
import {
  apiFail,
  apiSuccess,
  getAssets,
  getAsset,
  setAsset,
  getAssetSummary,
  getAvailabilities,
  getAvailabilitySchedule,
  getStorages,
  getUnits,
  getUnitSummary,
  overviewGenerated,
  postAvailabilityScheduleChanged,
  reapplyFilter,
  windowScrolled,
} from './actions'
import {
  selectCurrentAsset,
  selectCurrentAvailAbility,
  selectSchedule,
} from './selectors'
import { fromTable } from '../../features/availability/schedule.service'
import { EMPTY, Observable, of } from 'rxjs/index'
import { authLoginFailed, authLoginSuccess } from '../auth/auth.actions'
import { AssetAvailabilityUnit } from '../../../../../common/src/lib/services/assetsAvailabilityTimeseries.service'

import * as moment from 'moment'
import { ReportingSummaryResponse } from '../../../../../common/src/lib/services/reportingSummary.service'
import { NotificationService } from '../notifications/notification.service'
import { TranslateService } from '@ngx-translate/core'

@Injectable()
export class ApiEffects {
  reapplyFilter = createEffect(
    () =>
      this.actions$.pipe(
        ofType(reapplyFilter),
        switchMap(action => {
          this.store$.dispatch(
            // @ts-ignore
            apiSuccess({
              path: ['charts/refilter'],
              payload: {
                from: action.from,
                to: action.to,
              },
            })
          )

          return of({
            from: action.from,
            to: action.to,
          })
        })
      ),
    { dispatch: false }
  )
  overviewGenerated = createEffect(
    () =>
      this.actions$.pipe(
        ofType(overviewGenerated),
        switchMap(action => {
          this.store$.dispatch(
            // @ts-ignore
            apiSuccess({
              path: ['charts/overview_generate'],
              payload: {
                done: true,
              },
            })
          )
          return of({
            done: true,
          })
        })
      ),
    { dispatch: false }
  )
  windowScrollEvent = createEffect(
    () =>
      this.actions$.pipe(
        ofType(windowScrolled),
        switchMap(action => {
          this.store$.dispatch(
            // @ts-ignore
            apiSuccess({
              path: ['window/scrolled'],
              payload: {
                scrollTop: action.scrollTop,
              },
            })
          )
          return of({
            scrollTop: action.scrollTop,
          })
        })
      ),
    { dispatch: false }
  )
  getAssetSummary = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getAssetSummary),
        withLatestFrom(this.store$.pipe(select(selectCurrentAsset))),
        switchMap(([action, asset]) => {
          if (asset) {
            // @ts-ignore here we should say that schedule is AssetAvailability
            return this.reportingSummaryService
              .summary({
                asset_identifier: asset.identifier,
                customer_identifier: asset.customer_identifier,
              })
              .pipe(
                tap((response: ReportingSummaryResponse) => {
                  this.store$.dispatch(
                    // @ts-ignore
                    apiSuccess({
                      path: ['reporting', 'summary/asset'],
                      payload: response.results,
                    })
                  )
                }),
                catchError(error => {
                  this.store$.dispatch(apiFail({ error }))
                  return of(error)
                })
              )
          } else {
            return EMPTY
          }
        })
      ),
    { dispatch: false }
  )
  getUnitSummary = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getUnitSummary),
        withLatestFrom(this.store$.pipe(select(selectCurrentAsset))),
        switchMap(([action, asset]) =>
          // @ts-ignore here we should say that schedule is AssetAvailability
          this.reportingSummaryService
            .summary({
              asset_identifier: asset.identifier,
              customer_identifier: asset.customer_identifier,
              unit_identifier: action.identifier,
            })
            .pipe(
              tap((response: ReportingSummaryResponse) => {
                this.store$.dispatch(
                  // @ts-ignore
                  apiSuccess({
                    path: ['reporting', 'summary/asset'],
                    payload: response.results,
                  })
                )
              }),
              catchError(error => {
                this.store$.dispatch(apiFail({ error }))
                return of(error)
              })
            )
        )
      ),
    { dispatch: false }
  )
  getAssets = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getAssets),
        switchMap(() =>
          // @ts-ignore here we should say that schedule is AssetAvailability
          this.assetsService.find().pipe(
            tap((response: AssetResponses) => {
              this.store$.dispatch(
                // @ts-ignore
                apiSuccess({
                  path: ['asset', 'assets'],
                  payload: response.results,
                })
              )
              this.store$.dispatch(getAssetSummary({}))
            }),
            catchError(error => {
              this.store$.dispatch(apiFail({ error }))
              return of(error)
            })
          )
        )
      ),
    { dispatch: false }
  )
  getUnits = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getUnits),
        withLatestFrom(this.store$.pipe(select(selectCurrentAsset))),
        switchMap(([action, asset]) =>
          // @ts-ignore here we should say that schedule is AssetAvailability
          this.unitsService.findByAsset(asset.asset_identifier).pipe(
            tap((response: AssetUnitResponses) => {
              this.store$.dispatch(
                // @ts-ignore
                apiSuccess({
                  path: ['unit', 'units'],
                  payload: response.results,
                })
              )
            }),
            catchError(error => {
              this.store$.dispatch(apiFail({ error }))
              return of(error)
            })
          )
        )
      ),
    { dispatch: false }
  )
  getStorage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getStorages),
        withLatestFrom(this.store$.pipe(select(selectCurrentAsset))),
        switchMap(([action, asset]) =>
          // @ts-ignore here we should say that schedule is AssetAvailability
          this.storageService.findByAsset(asset.asset_identifier).pipe(
            tap((response: AssetsStorageResponses) => {
              this.store$.dispatch(
                // @ts-ignore
                apiSuccess({
                  path: ['storage', 'storages'],
                  payload: response.results,
                })
              )
            }),
            catchError(error => {
              this.store$.dispatch(apiFail({ error }))
              return of(error)
            })
          )
        )
      ),
    { dispatch: false }
  )
  getAvailabilities = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getAvailabilities),
        withLatestFrom(this.store$.pipe(select(selectCurrentAsset))),
        switchMap(([action, asset]) =>
          this.assetsAvailabilityService.findByAsset(asset.identifier).pipe(
            tap((response: AssetAvailabilityResponses) => {
              this.store$.dispatch(
                // @ts-ignore
                apiSuccess({
                  path: ['availability', 'availabilities'],
                  payload: response.results,
                })
              )
            }),
            catchError(error => {
              this.store$.dispatch(apiFail({ error }))
              return of(error)
            })
          )
        )
      ),
    { dispatch: false }
  )
  getAvailabilitySchedule = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getAvailabilitySchedule),
        withLatestFrom(this.store$.pipe(select(selectCurrentAsset))),
        switchMap(([action, asset]) => {
          if (asset) {
            return this.assetsAvailabilityScheduleService
              .findByAsset(asset.identifier, action.from, action.to)
              .pipe(
                tap((response: AssetAvailabilityScheduleResponse) => {
                  this.store$.dispatch(
                    // @ts-ignore
                    apiSuccess({
                      path: ['asset', 'availability/schedule'],
                      payload: response,
                    })
                  )
                }),
                catchError(error => {
                  this.store$.dispatch(apiFail({ error }))
                  return of(error)
                })
              )
          } else {
            return EMPTY
          }
        })
      ),
    { dispatch: false }
  )
  // TODO @victor which endpoint we should use now?
  postAvailabilitySchedule = createEffect(
    () =>
      this.actions$.pipe(
        ofType(postAvailabilityScheduleChanged),
        withLatestFrom(this.store$.pipe(select(selectSchedule))),
        tap(console.warn),
        switchMap(([{ customer_reason }, e]) => {
          let a = fromTable(e.table, e.schedule)
          // console.warn({customer_reason})
          let all: AssetAvailabilities = new AssetAvailabilities()
          all.availabilities = []

          for (let i = 0; i < e.schedule.length; i++) {
            let schedule = e.schedule[i]
            if (!schedule.modified) {
              continue
            }
            //create one per day that is modified
            let avail: AssetAvailability = new AssetAvailability()
            avail.asset_identifier = schedule.asset_identifier
            avail.customer_reason = customer_reason

            let mm = moment(schedule.date)
            avail.effective_from = mm.toISOString(true)
            avail.effective_to = mm.add(1, 'day').toISOString(true)

            avail.results = []
            //iterate over units and values

            /**
             example input
             0:
             asset_identifier: "XKlXaTxOrB"
             asset_name: "11yarVRsxh"
             date: "2019-01-01"
             modified: "1"
             units: Array(2)
             0:
             installed_capacity: 801.9566
             unit_identifier: "gDXyDuqrDW"
             unit_label: "APxeWl7ErW"
             values: Array(96)
             0:
             ts: "00:00"
             value: 1
             *
             */

            /**

             Needed output

             {
  "availabilities": [
    {
      "asset_identifier": "string",
      "customer_reason": "string",
      "effective_from": "2019-11-27T10:33:39.578Z",
      "effective_to": "2019-11-27T10:33:39.578Z",
      "identifier": "string",
      "priority": 0,
      "results": [
        {
          "ts": "string",
          "us": [
            {
              "u": "string",
              "v": 0
            }
          ]
        }
      ],
      "schedule": "string"
    }
  ]
}
             */
            for (let j = 0; j < schedule.units.length; j++) {
              let unit = schedule.units[j] //loops twice

              for (let k = 0; k < unit.values.length; k++) {
                //find the existing ts or create
                let ts: AssetAvailabilityTimeseries = null

                let val = unit.values[k] //loops 96  times (15 min per day)

                //ts represents a timestamp with units and values
                for (let t = 0; t < avail.results.length; t++) {
                  if (avail.results[t].ts == val.ts) {
                    //found a match
                    ts = avail.results[t]
                  }
                }
                if (ts == null) {
                  ts = new AssetAvailabilityTimeseries()
                  //use the first value as the timestamp
                  ts.ts = val.ts
                  ts.us = []
                  avail.results.push(ts)
                }

                let us: AssetAvailabilityUnit = new AssetAvailabilityUnit()
                us.u = unit.unit_identifier
                us.v = val.value
                ts.us.push(us)
              }
            }
            all.availabilities.push(avail)
          }

          return this.assetsAvailabilityService
            .createAll(e.schedule[0].asset_identifier, all)
            .pipe(
              tap(() => {
                this.notificationService.success(
                  this.t.instant('availability.notification.success')
                )
              }),
              catchError(error => {
                this.store$.dispatch(apiFail({ error }))
                return of(error)
              })
            )

          //   {
          //   asset_identifier: e.schedule[0].asset_identifier,
          //   customer_reason,
          //   results: fromTable(e.table, e.schedule),
        })
      ),
    { dispatch: false }
  )

  constructor(
    private actions$: Actions,
    private store$: Store<ApiState>,
    private assetsAvailabilityService: AssetsAvailabilityService,
    private assetsService: AssetsService,
    private assetsAvailabilityScheduleService: AssetsAvailabilityScheduleService,
    private unitsService: AssetsUnitsService,
    private storageService: AssetsStoragesService,
    private reportingSummaryService: ReportingSummaryService,
    private assetsAvailabilityTimeseriesService: AssetsAvailabilityTimeseriesService,
    private notificationService: NotificationService,
    private t: TranslateService
  ) {}

  // @Effect({ dispatch: false })
  // postAvailability$ =
  //   this.actions$
  //     .ofType(postAvailabilityWarning)
  //     .switchMap(action =>
  //         this.assetsAvailabilityTimeseriesService.create({
  //           asset_identifier: 'string',
  //           customer_reason: 'string',
  //           effective_from: '2019-11-20T15:39:24.641Z',
  //           effective_to: '2019-11-20T15:39:24.641Z',
  //           identifier: 'string',
  //           priority: 0,
  //           schedule: 'string'
  //         })
  //       // .map(availability => ({type: 'postAvaialbilitySuccessfull', payload: availability}))
  //       // .catch(() => Observable.of({type: 'postAvaialbilityFailure'}))
  //     )
}
