import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { LOCALE_ID, NgModule } from '@angular/core'

import { SharedModule } from './shared/shared.module'
import { CoreModule } from './core/core.module'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app/app.component'
import { LoginComponent } from './features/login/component'
import { ServicesModule } from 'common'
import { environment } from '../environments/environment'
import { registerLocaleData } from '@angular/common'
import localeDe from '@angular/common/locales/de'

registerLocaleData(localeDe, 'de-DE')

@NgModule({
  providers: [
    { provide: LOCALE_ID, useValue: 'de-DE' }, //replace "en-US" with your locale
  ],
  imports: [
    // angular
    BrowserAnimationsModule,
    BrowserModule,

    // core & shared
    CoreModule,
    SharedModule,
    ServicesModule.forRoot(environment),

    // app
    AppRoutingModule,
  ],
  declarations: [AppComponent, LoginComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
