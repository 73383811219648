import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { selectIsAuthenticated } from './auth.selectors'
import { AppState } from '../core.state'

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(selectIsAuthenticated),
      map((authenticated: boolean) => {
        if (authenticated) {
          return true
        } else {
          this.router.navigateByUrl('login')
          return false
        }
      })
    )
  }
}
