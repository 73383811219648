import {
  chain as flatMap,
  map,
  mapObjIndexed,
  merge,
  omit,
  pipe as _,
  tap,
  test,
  toPairs,
} from 'ramda'

// TODO move the file to service

// O(n^3)
export const toTable = _(
  schedule =>
    schedule.map(day =>
      day.units.reduce((acc, unit) => {
        unit.values.map(({ ts, value }) => {
          if (!test(/:00/)(ts)) {
            return // skipp non full hours
          }
          if (!acc[ts]) {
            acc[ts] = {}
          }
          acc[ts] = merge(
            {
              [unit.unit_identifier]: value,
            },
            acc[ts]
          )
        })
        return acc
      }, {})
    ),
  // add total prop
  map(
    mapObjIndexed((values, hour) => {
      // @ts-ignore maybe we can calculate here
      values.total = 666
      return values
    })
  ),
  // '00:00': { hmDmz1n9Ns: 161.6325, gDXyDuqrDW: 801.9566 },
  map(day => {
    const val = []
    Object.keys(day).forEach(hour => val.push({ hour, ...day[hour] }))
    return val
  })
  // { hour: '00:00', hmDmz1n9Ns: 161.6325, gDXyDuqrDW: 801.9566 },
)

const setMinutes = min => hour => hour.split(':00').join(':' + min)

const addMinutes = min => hourData =>
  merge(hourData, { hour: setMinutes(min)(hourData.hour) })

export const fromTable = (table, schedule) =>
  table
    .filter((day, i) => schedule[i].modified)
    .map(day => day.map(omit(['total']))) // Totals were only for display on mat-table
    .map(
      _(
        // unfold minutes
        flatMap(
          // AKA flatmap
          hour => [hour, addMinutes(15)(hour), addMinutes(30)(hour), addMinutes(45)(hour)]
        ),
        // pivot
        flatMap(minutes =>
          _(
            // @ts-ignore
            omit(['hour']),
            toPairs,
            map(e => ({
              // @ts-ignore
              ts: minutes.hour,
              us: [
                {
                  u: e[0],
                  v: e[1],
                },
              ],
            }))
          )(minutes)
        )
      )
    )
