import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs/index'

@Injectable({
  providedIn: 'root',
})
export class AssetsAvailabilityTimeseriesService {
  constructor(private http: HttpClient, @Inject('env') private env) {}

  public get(
    assetIdentifier: string,
    identifier: string
  ): Observable<AssetAvailabilityTimeseriesResponses> {
    return this.http.get<AssetAvailabilityTimeseriesResponses>(
      `${this.env.apiBaseUrl}/assets/${assetIdentifier}/availability/${identifier}/timeseries`
    )
  }

  public update(
    assetIdentifier: string,
    availabilityIdentifier: string,
    availabilityTimeseries: AssetAvailabilityTimeseriesUpdateRequest
  ): Observable<AssetAvailabilityTimeseriesResponses> {
    return this.http.put<AssetAvailabilityTimeseriesResponses>(
      `${this.env.apiBaseUrl}/assets/${assetIdentifier}/availability/${availabilityIdentifier}/timeseries`,
      availabilityTimeseries
    )
  }
}

export class AssetAvailabilityTimeseriesResponses {
  results: AssetAvailabilityTimeseries[]
}

export class AssetAvailabilityTimeseriesUpdateRequest {
  results: AssetAvailabilityTimeseries[]
}

export class AssetAvailabilityTimeseries {
  ts: string
  us: AssetAvailabilityUnit[]
}

export class AssetAvailabilityUnit {
  u: string
  v: number
}
