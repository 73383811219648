import { ActionReducerMap, MetaReducer, createFeatureSelector } from '@ngrx/store'
import { routerReducer, RouterReducerState } from '@ngrx/router-store'

import { environment } from '../../environments/environment'

import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer'
import { debug } from './meta-reducers/debug.reducer'
import { AuthState } from './auth/auth.models'
import { authReducer } from './auth/auth.reducer'
import { RouterStateUrl } from './router/serializer'
import { settingsReducer } from './settings/settings.reducer'
import { SettingsState } from './settings/settings.model'
import { ApiState, apiReducer } from './api/reducer'

// @ts-ignore api is still unspecified
export const reducers: ActionReducerMap<AppState> = {
  api: apiReducer,
  auth: authReducer,
  settings: settingsReducer,
  router: routerReducer,
}

export const metaReducers: MetaReducer<AppState>[] = [initStateFromLocalStorage]

if (!environment.production) {
  if (!environment.test) {
    metaReducers.unshift(debug)
  }
}

export const selectAuthState = createFeatureSelector<AppState, AuthState>('auth')

export const selectSettingsState = createFeatureSelector<AppState, SettingsState>(
  'settings'
)
export const selectApiState = createFeatureSelector<AppState, SettingsState>('api')

export const selectRouterState = createFeatureSelector<
  AppState,
  RouterReducerState<RouterStateUrl>
>('router')

export interface AppState {
  api: ApiState
  auth: AuthState
  router: RouterReducerState<RouterStateUrl>
  settings: SettingsState
}
