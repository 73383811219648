import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { ofType, createEffect, Actions } from '@ngrx/effects'
import { catchError, map, switchMap, tap } from 'rxjs/operators'

import { LocalStorageService } from '../local-storage/local-storage.service'

import { authLogin, authLoginFailed, authLoginSuccess, authLogout } from './auth.actions'
import { LoginService } from 'common'
import { LoginResponse } from '../../../../../common/src/lib/services/login.service'
import { Observable, of, throwError } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from '../core.state'
import { getAssets } from '../api/actions'

export const AUTH_KEY = 'AUTH'

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private loginService: LoginService,
    private router: Router,
    private store: Store<AppState>
  ) {}

  login = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLogin),
        switchMap(r =>
          this.loginService.login(r.username, r.password).pipe(
            tap((res: LoginResponse) => {
              this.store.dispatch(authLoginSuccess({ token: res.token }))
            }),
            catchError(error => {
              this.store.dispatch(authLoginFailed({ error: error }))
              return of(error)
            })
          )
        )
      ),
    { dispatch: false }
  )

  loginSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLoginSuccess),
        switchMap((r: any) => {
          //removed getAssets req since doesn't help in case of page refresh ->
          // moved into the only place that makes sense for now: app component (temp)
          //this.store.dispatch(getAssets({}))

          let token = r.token.substring('Bearer '.length)
          this.localStorageService.setItem(AUTH_KEY, {
            isAuthenticated: true,
            token,
          })
          window.document.cookie = 'Authorization=' + r.token
          return of(r)
        })
      ),
    { dispatch: false }
  )

  loginFail = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLoginFailed),
        tap(r => console.log('Failed', r))
      ),
    { dispatch: false }
  )

  logout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLogout),
        tap(() => {
          this.router.navigate([''])
          this.localStorageService.setItem(AUTH_KEY, {
            isAuthenticated: false,
          })
        })
      ),
    { dispatch: false }
  )
}
