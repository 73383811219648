import { createAction, props } from '@ngrx/store'

export const authLogin = createAction(
  '[Auth] Login',
  props<{ username: string; password: string }>()
)
export const authLogout = createAction('[Auth] Logout')

export const authLoginFailedName = '[Auth] Login Failed'
export const authLoginFailed = createAction(
  authLoginFailedName,
  props<{ error: string }>()
)
export const authLoginSuccessName = '[Auth] Login Success'
export const authLoginSuccess = createAction(
  authLoginSuccessName,
  props<{ token: string }>()
)
