import { Action, createReducer, on } from '@ngrx/store'
import * as apiMockState from '../../../assets/api'
import { apiSuccess, setAvailabilityScheduleChanged, setAsset } from './actions'
// import { apiReportingSummaryLoadAction } from '../api-reporting/apiReporting.actions'
import { assocPath, mergeDeepLeft } from 'ramda'

export type ApiState = any // TODO real state type

const reducer = createReducer(
  apiMockState.default(),
  on(apiSuccess, (state, action) => ({
    ...state,
    // @ts-ignore
    ...assocPath(action.path, action.payload)(state),
  })),
  // on(postAvailabilityScheduleChanged, (state, action) => ({
  //   ...state,
  //   todo: 'finishme',
  //   ...action,
  // })),
  on(setAvailabilityScheduleChanged, (state, action) => ({
    ...state,
    schedule: { table: action.table, schedule: action.schedule },
  })),
  on(setAsset, (state, action) => ({
    ...state,
    ...assocPath(['asset', 'asset'], action.asset)(state),
  }))
  // on(postAvailabilitySchedule, state => ({ ...state, todo: 'finishme' }))
)

export function apiReducer(state: any, action: Action) {
  return reducer(state, action)
}
