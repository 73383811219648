import { Injectable } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { createEffect } from '@ngrx/effects'
import { tap, filter } from 'rxjs/operators'

@Injectable()
export class GoogleAnalyticsEffects {
  constructor(private router: Router) {}

  pageView = createEffect(
    () => () =>
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          // @ts-ignore
          if (typeof (<any>window).ga === 'function') {
            // @ts-ignore
            ga('set', 'page', event.urlAfterRedirects)
            // @ts-ignore
            ga('send', 'pageview')

            // (<any>window).ga('set', 'page', event.urlAfterRedirects)
            // (<any>window).ga('send', 'pageview')
          }
        })
      ),
    { dispatch: false }
  )
}
