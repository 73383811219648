import { createAction, props } from '@ngrx/store'
import { Asset, AssetAvailability, AssetAvailabilityScheduleResponse } from 'common'

/**
 * Generic API Failure
 */
export const apiFail = createAction('[API] Fail', props<{ error: string }>())

export const apiSuccess = createAction(
  '[API] Success',
  // @ts-ignore type all possibilities
  props<{
    path: string[]
    payload:
      | object
      | AssetAvailability[]
      | Asset[]
      | Asset
      | AssetAvailabilityScheduleResponse
  }>()
)

/**
 * Chart Actions
 *
 */
export const reapplyFilter = createAction(
  '[Charts] Apply Filter',
  props<{ from: string; to: string }>()
)

export const overviewGenerated = createAction('[Charts] Overview Generated', props())

/**
 * UI Actions
 */

export const windowScrolled = createAction(
  '[Window] Content Scrolled',
  props<{ scrollTop: number }>()
)

/**
 * API Actions
 */
export const getAssets = createAction('[Asset] Get Asset', props())
export const getStorages = createAction('[Storage] Get Storages', props())
export const getUnits = createAction('[Unit] Get Units', props())

export const getAsset = createAction(
  '[Asset] Get Picked',
  props<{ identifier: string }>()
)
export const setAsset = createAction('[Asset] Set Picked', props<{ asset: any }>())

export const getAssetSummary = createAction('[Reporting] Get Asset Summary', props())

export const getUnitSummary = createAction(
  '[Reporting] Get Unit Summary',
  props<{ identifier: string }>()
)

/**
 * Availability actions
 */

export const getAvailabilities = createAction('[Availability] Get availability', props())

export const getAvailabilitySchedule = createAction(
  '[Availability] Get Availability Schedule',
  props<{ from: string; to: string }>()
)

export const postAvailabilityScheduleChanged = createAction(
  '[Availability] Post changed reason',
  props<{ customer_reason: string }>()
)

export const setAvailabilityScheduleChanged = createAction(
  '[Availability] Set schedule changed',
  props<{ table: any[]; schedule: any[] }>()
)

// export const postAvailabilitySchedule = createAction(
//   '[Availability] Post schedule',
//   props<{ ts: string; us: any[] }>()
// )
