// (mostly) without `.results` and `.total_results`
module.exports = () => ({
  asset: {
    asset: {},
    assets: [],
    'availability/schedule': {},
  },
  availability: {
    availability: {},
    availabilities: [],
  },
  contract: {
    contract: {
      identifier: 'CN_2f0976e105314eb396c96b3c6373aa4c',
      customer_identifier: 'CU_d7b6db30dacd409fac6d91adb25267c3',
      customer_name: 'ZiaglFA tYsQpxZSvPX',
      status: 'Active',
      start_date: '2019-01-01',
      end_date: '2019-12-31',
      portfolio: null,
      contract_number: null,
      counter_party: null,
      guaranteed_cutoff_time: '18:00:00',
      guaranteed_cutoff_increment: 1440,
      guaranteed_lead_time: 2880,
      unguaranteed_lead_time: 60,
      created_at: '2019-11-20T15:54:28.001167',
      updated_at: '2019-11-20T15:54:28.001167',
    },
    contracts: [
      {
        identifier: 'CN_2f0976e105314eb396c96b3c6373aa4c',
        customer_identifier: 'CU_d7b6db30dacd409fac6d91adb25267c3',
        customer_name: 'ZiaglFA tYsQpxZSvPX',
        status: 'Active',
        start_date: '2019-01-01',
        end_date: '2019-12-31',
        portfolio: null,
        contract_number: null,
        counter_party: null,
        guaranteed_cutoff_time: '18:00:00',
        guaranteed_cutoff_increment: 1440,
        guaranteed_lead_time: 2880,
        unguaranteed_lead_time: 60,
        created_at: '2019-11-20T15:54:28.001167',
        updated_at: '2019-11-20T15:54:28.001167',
      },
      {
        identifier: 'CN_1b53176afa5940f4ab53a2a4d8d2529d',
        customer_identifier: 'CU_f7a0b88c54f741c5a49e8cf8824cfb15',
        customer_name: 'BJapiDyu CyCEXTj nWlNpyzsyvXeZ',
        status: 'Active',
        start_date: '2019-01-01',
        end_date: '2019-12-31',
        portfolio: null,
        contract_number: null,
        counter_party: null,
        guaranteed_cutoff_time: '18:00:00',
        guaranteed_cutoff_increment: 1440,
        guaranteed_lead_time: 2880,
        unguaranteed_lead_time: 60,
        created_at: '2019-11-20T15:54:28.177211',
        updated_at: '2019-11-20T15:54:28.177211',
      },
      {
        identifier: 'CN_aadc4d71701440279c00014d1726665d',
        customer_identifier: 'CU_12c1d9ea555d4d4cb37028b1fb8b1f8e',
        customer_name: 'SkqfPbWjVAt FAIZcfd',
        status: 'Active',
        start_date: '2019-01-01',
        end_date: '2019-12-31',
        portfolio: null,
        contract_number: null,
        counter_party: null,
        guaranteed_cutoff_time: '18:00:00',
        guaranteed_cutoff_increment: 1440,
        guaranteed_lead_time: 2880,
        unguaranteed_lead_time: 60,
        created_at: '2019-11-20T16:23:18.903961',
        updated_at: '2019-11-20T16:23:18.903961',
      },
      {
        identifier: 'CN_e139d40ff0134cd39e07d1ffcfb07df0',
        customer_identifier: 'CU_79f4c81a82da452d8f6ddd151be31567',
        customer_name: 'ViRQua',
        status: 'Active',
        start_date: '2019-01-01',
        end_date: '2019-12-31',
        portfolio: null,
        contract_number: null,
        counter_party: null,
        guaranteed_cutoff_time: '18:00:00',
        guaranteed_cutoff_increment: 1440,
        guaranteed_lead_time: 2880,
        unguaranteed_lead_time: 60,
        created_at: '2019-11-20T16:23:52.089789',
        updated_at: '2019-11-20T16:23:52.089789',
      },
      {
        identifier: 'CN_9ced40f3399f4b928daf39a07cc9d86a',
        customer_identifier: 'CU_596c79af962549569881bf0cdbd8ec13',
        customer_name: 'OFsUEmm pXPfSH Ind',
        status: 'Active',
        start_date: '2019-01-01',
        end_date: '2019-12-31',
        portfolio: null,
        contract_number: null,
        counter_party: null,
        guaranteed_cutoff_time: '18:00:00',
        guaranteed_cutoff_increment: 1440,
        guaranteed_lead_time: 2880,
        unguaranteed_lead_time: 60,
        created_at: '2019-11-20T16:23:53.346789',
        updated_at: '2019-11-20T16:23:53.346789',
      },
      {
        identifier: 'CN_6093329a6be546e0bbc6db4881881829',
        customer_identifier: 'CU_9c28b19db9884d5daf93e586ef0f4f7c',
        customer_name: 'FkT cibQV reCv',
        status: 'Active',
        start_date: '2019-01-01',
        end_date: '2019-12-31',
        portfolio: null,
        contract_number: null,
        counter_party: null,
        guaranteed_cutoff_time: '18:00:00',
        guaranteed_cutoff_increment: 1440,
        guaranteed_lead_time: 2880,
        unguaranteed_lead_time: 60,
        created_at: '2019-11-20T16:23:53.871632',
        updated_at: '2019-11-20T16:23:53.871632',
      },
      {
        identifier: 'CN_8acb2444f2534c91abb37bba19ca35fd',
        customer_identifier: 'CU_3b3513059c7643d98637b1e9d2d89ea4',
        customer_name: 'FCqMLf qCnxnp BHSNozURcJk',
        status: 'Active',
        start_date: '2019-01-01',
        end_date: '2019-12-31',
        portfolio: null,
        contract_number: null,
        counter_party: null,
        guaranteed_cutoff_time: '18:00:00',
        guaranteed_cutoff_increment: 1440,
        guaranteed_lead_time: 2880,
        unguaranteed_lead_time: 60,
        created_at: '2019-11-20T16:23:54.077776',
        updated_at: '2019-11-20T16:23:54.077776',
      },
      {
        identifier: 'CN_480a1210782b4df288a41548ea5cc8ca',
        customer_identifier: 'CU_1997e918a2a7404aa9110a94a296fb83',
        customer_name: 'TfUhKYOjlu',
        status: 'Active',
        start_date: '2019-01-01',
        end_date: '2019-12-31',
        portfolio: null,
        contract_number: null,
        counter_party: null,
        guaranteed_cutoff_time: '18:00:00',
        guaranteed_cutoff_increment: 1440,
        guaranteed_lead_time: 2880,
        unguaranteed_lead_time: 60,
        created_at: '2019-11-20T16:24:47.499932',
        updated_at: '2019-11-20T16:24:47.499932',
      },
      {
        identifier: 'CN_f4ab8628ff834108a879586cc33ffe6f',
        customer_identifier: 'CU_1e4dc0fa0dd64a49a34324bed42c4a9d',
        customer_name: 'DLRuDhTrR zyAfnTiyV',
        status: 'Active',
        start_date: '2019-01-01',
        end_date: '2019-12-31',
        portfolio: null,
        contract_number: null,
        counter_party: null,
        guaranteed_cutoff_time: '18:00:00',
        guaranteed_cutoff_increment: 1440,
        guaranteed_lead_time: 2880,
        unguaranteed_lead_time: 60,
        created_at: '2019-11-20T16:24:48.779564',
        updated_at: '2019-11-20T16:24:48.779564',
      },
      {
        identifier: 'CN_ccb226ed15504a92bafee1859d765a6b',
        customer_identifier: 'CU_c67bd808d7714ea1ba997bce0bc441c0',
        customer_name: 'TbCMRZyex MBKDwBuDkoBn',
        status: 'Active',
        start_date: '2019-01-01',
        end_date: '2019-12-31',
        portfolio: null,
        contract_number: null,
        counter_party: null,
        guaranteed_cutoff_time: '18:00:00',
        guaranteed_cutoff_increment: 1440,
        guaranteed_lead_time: 2880,
        unguaranteed_lead_time: 60,
        created_at: '2019-11-20T15:54:27.686147',
        updated_at: '2019-11-20T15:54:27.686147',
      },
    ],
  },
  reporting: {
    'summary/unit': [],
    'summary/asset': [],
  },
  unit: {
    units: [
      {
        identifier: 'RjRjRgpdSi',
        asset_identifier: 'LfXDrRyL5Y',
        installed_capacity: 423.9298,
        label: 'q17jQHeyT0',
        description:
          'wenKJhg jWO KRRNlOL VswzdwxwoBAys KSW ugJTXTFU zSJ viwCo AAGejakoTniL MsJqeHGXNPz wVXclHehgm eYwiHM GayDRYhMF YkOptvnnKCpi RdHJKmiLAnOwc JCUIui dHyj vzkbY PKDm RqREwMsFqVxC DCdDwgKEFnaLME ryvuLw cTCgjojeQsa eLKEkog ysyGdsBQgTKR gGYuoY bFDFgPHOxJUg ukcLRLrjfk PjXoqHDeBELVn RHFzWjQxzPc gcZwAAFOzoqaVB lEdhaLSJ wGzCXeqbbDXYd PDohnSDsqlY LukeEuD GzSvoSnt IzWjEoT ecTHZXkZntFxdF GryNSYAGNn PpLnKF jCD xBODSap uIoUlptuKuE MXKCMyusK QPMhli gvxYiz MhFn UDjfRbdi Myh zHCeHREPS uKDfCeVc iraEvFQpvfwl jVJR jxZoehDXSWO qzYAAK GaYOeGvyFPe jJoObx VjORpt YWUCHUMtL Htz xnWHyCrmqqaTwx lmgqH LTO hSuAQKsjCO rYAttaKrAV zrBJfjpYKD oWdYvOiQq',
        manufacturer: 'UCQgKqXB rEuoCZY',
        type: 'Ip1OmGFBwm',
        coordinate_type: 'Point',
        latitude: 46.6969,
        longitude: 4.2445,
        eeg_id: 'gkZdh2FvYT',
        em_anlagen_id: 'VWlHOSZBSc',
        mrl_via_potential_power: 'aAJYZ0VjoL',
        down_regulation_allowed: true,
        down_regulation_in_percentage: 89,
        down_regulation_min_power: 2.2263,
        down_regulation_max_power: 19.7289,
        down_regulation_shutdown: 'fOcgi7MImm',
        down_regulation_from: '2019-11-20T16:23:53Z',
        software_provider: 'aFeNpze0fe',
        software_id: 'amXownH15P',
        ibn: 'Xk3jX10Zro',
        serial: 'S24QNKylyv',
        effective_from: '2019-11-20T00:00:00Z',
        effective_to: null,
        updated_at: '2019-11-20T16:23:53Z',
        created_at: '2019-11-20T16:23:53Z',
      },
      {
        identifier: 'tz4EGebUTJ',
        asset_identifier: 'LfXDrRyL5Y',
        installed_capacity: 907.4441,
        label: 'eIY9TXSFKP',
        description:
          'RVrDPBpEkBJgNt AQcMxoRxifrGk SfNFzciGKBPD dVXt UjyqXRwZGUgu oJXth KBcxA nkcV ystRiLhNrFO pfn Cmorr PXpBW klwbAjJiNCKY PPnYQvHv EcneS CiSkovWyC noKimcWs aUZbCzqkPj FgxVF bbokFQYRtpP Usvf nhXaEVbbLIoO lYvDEAPvJhXoM SWomBPSDX RUwcrQ KIqgzJTWszicX QHqnu pRTAUvlqF NnZBRDyLol',
        manufacturer: 'ZYN VyRSzGyn',
        type: 'iBwhccwghc',
        coordinate_type: 'Point',
        latitude: 0.7849,
        longitude: 56.1611,
        eeg_id: 'yc7UweKsrb',
        em_anlagen_id: 'mB6ph6gpmI',
        mrl_via_potential_power: 'yC0B4hZXDU',
        down_regulation_allowed: true,
        down_regulation_in_percentage: 43,
        down_regulation_min_power: 1.8688,
        down_regulation_max_power: 17.0016,
        down_regulation_shutdown: 'sKXjx5bDMw',
        down_regulation_from: '2019-11-20T16:23:53Z',
        software_provider: 'VQnnIzcxWn',
        software_id: 'CIVkQQSSFS',
        ibn: 'MYbsQxDbud',
        serial: 'Kx6bcroc8o',
        effective_from: '2019-11-20T00:00:00Z',
        effective_to: null,
        updated_at: '2019-11-20T16:23:53Z',
        created_at: '2019-11-20T16:23:53Z',
      },
    ],
  },
  schedule: {}, // for modification of asset/availability/schedule
})
